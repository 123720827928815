import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FeatureCollection } from 'geojson';
import { LngLat } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { MapboxIsochroneService, RoutingProfile } from '../shared/mapbox-isochrone.service';

@Component({
    selector: 'hm-isochrone',
    templateUrl: './isochrone.component.html',
    styleUrls: ['./isochrone.component.scss'],
})
export class IsochroneComponent implements OnInit, OnChanges {
    @Input() routingProfile: RoutingProfile;
    @Input() coordinates: LngLat;
    @Input() contoursMinutes: number[];
    @Input() polygons: boolean;
    @Input() showIsochrones: 'visible' | 'none' = 'visible';

    @Output() isochroneEvent = new EventEmitter<FeatureCollection>();

    isochrones = new BehaviorSubject<FeatureCollection>({
        type: 'FeatureCollection',
        features: [],
    });

    constructor(private isochroneService: MapboxIsochroneService) {}

    ngOnInit(): void {
        if (this.coordinates) {
            this.getData();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.values(changes).some((c) => !c.isFirstChange())) {
            if (this.coordinates) {
                this.getData();
            }
        }
    }

    getData() {
        this.isochroneService
            .getIsochrone(
                this.routingProfile,
                this.coordinates,
                this.contoursMinutes,
                this.polygons
            )
            .subscribe((iso: FeatureCollection) => {
                this.isochrones.next(iso);
                this.isochroneEvent.emit(iso);
            });
    }
}
