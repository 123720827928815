import { environment } from 'environments/environment';
import { AppMenus } from '../_base/layout/models/menu-config.model';

export class MenuConfig {
    public defaults: AppMenus = {
        header: {
            items: [
                {
                    title: 'Region profiler',
                    root: true,
                    icon: 'flaticon-map-location',
                    permission: ['REGION_PROFILER'],
                    submenu: [
                        {
                            title: 'Dashboards',
                            description:
                                'Interactive dashboards showing regional statistics and charts',
                            icon: 'flaticon-graphic-2',
                            bullet: 'dot',
                            permission: [
                                'RR_TA_REGION',
                                'RR_TA_CATEGORY',
                                'RR_DASHBOARD',
                                'RR_PROFILER',
                                'RR_FROMWHERE',
                                'RR_WHERETO',
                            ],
                            submenu: [
                                {
                                    title: 'Snapshot',
                                    description:
                                        'Choose a region and date to display the key summary statistics and charts',
                                    page: '/regions/dashboard',
                                    permission: 'RR_DASHBOARD',
                                },
                                {
                                    title: 'Compare',
                                    page: '/regions/region-compare',
                                    bullet: 'dot',
                                    description:
                                        'Compare multiple regions or multiple dates across the key summary statistics',
                                    permission: 'RR_DASHBOARD_COMPARISON',
                                },
                                {
                                    title: 'Region Visitation',
                                    page: '/regions/fromwhere',
                                    description:
                                        'Shows a heatmap of where visitors come from in a selected region and date',
                                    permission: 'RR_FROMWHERE',
                                },
                                {
                                    title: 'Out-bound visits',
                                    page: '/regions/whereto',
                                    description:
                                        'Shows a heatmap of where visitors travel to from a selected region and date',
                                    permission: 'RR_WHERETO',
                                },
                                {
                                    title: 'Region Visitation trend',
                                    page: '/regions/trend-analysis-by-region',
                                    description:
                                        'Shows trended visitation data in regions across a specified date range',
                                    permission: 'RR_TA_REGION',
                                },
                                {
                                    title: 'Expenditure trend',
                                    page: '/regions/trend-analysis-by-category',
                                    description:
                                        'Shows trended expenditure data in regions across a specified date range',
                                    permission: 'RR_TA_CATEGORY',
                                },
                            ],
                        },
                        {
                            title: 'Pivot tables',
                            description:
                                'Drill-down analysis of all region profile data and summary statistics',
                            icon: 'flaticon-squares-3',
                            bullet: 'dot',
                            permission: [
                                'RA_PROFILER_GPS',
                                'RA_PROFILER_IFID',
                                'RA_PROFILER_IFII',
                                'RA_TREND_IFID_INDEX',
                                'RA_TREND_IFII_INDEX',
                                'RA_PROFILER_CELLULAR',
                            ],
                            submenu: [
                                {
                                    title: 'Region Visitation trend',
                                    description:
                                        'Drill down analysis of longitudinal visitation data',
                                    page: '/analysis/trend:device-gps',
                                    permission: 'RA_PROFILER_GPS',
                                },
                                {
                                    title: 'Region Visitation trend (Cellular)',
                                    description:
                                        'Drill down analysis of longitudinal visitation data',
                                    page: '/analysis/trend:cellular',
                                    permission: 'RA_PROFILER_CELLULAR',
                                },
                                {
                                    title: 'Domestic trend',
                                    description:
                                        'Drill down analysis of longitudinal domestic expenditure data',
                                    page: '/analysis/trend:ifi-domestic',
                                    permission: 'RA_PROFILER_IFID',
                                },
                                {
                                    title: 'Domestic index trend',
                                    description:
                                        'Drill down analysis of longitudinal domestic expenditure index data',
                                    page: '/analysis/trend:ifi-domestic-segmented-index',
                                    permission: 'RA_TREND_IFID_INDEX',
                                },
                                {
                                    title: 'International trend',
                                    description:
                                        'Drill down analysis of longitudinal international expenditure data',
                                    page: '/analysis/trend:ifi-international',
                                    permission: 'RA_PROFILER_IFII',
                                },
                                {
                                    title: 'International index trend',
                                    description:
                                        'Drill down analysis of longitudinal international expenditure index data',
                                    page: '/analysis/trend:ifi-international-index',
                                    permission: 'RA_TREND_IFII_INDEX',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            page: '/downloads/region-profiler',
                            permission: ['RP_DOWNLOADS'],
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            bullet: 'none',
                            page: '/downloads/region-profiler/help',
                            permission: ['RP_HELP'],
                            single: true,
                            // submenu: [
                            //     {
                            //         title: 'Regional profiler help & documentation',
                            //         description: 'pdf/ppt downloadable help and documentation',
                            //         page: '/downloads/region-profiler/help',
                            //         permission: 'RP_HELP',
                            //     },
                            // ],
                        },
                    ],
                },
                {
                    title: 'Digital marketing',
                    root: true,

                    icon: 'flaticon-responsive',
                    permission: ['DIGITAL_MARKETING'],
                    submenu: [
                        {
                            title: 'Dashboards',
                            bullet: 'dot',
                            description:
                                'Get detailed reports that will help you make more informed decisions',
                            icon: 'flaticon-graphic-2',
                            permission: ['DI_PROFILING', 'DI_TREND'],
                            submenu: [
                                {
                                    title: 'Profiling',
                                    description:
                                        'Choose a region and date to display the key summary statistics and charts',
                                    page: '/digital-integration/profile',
                                    permission: 'DI_PROFILING',
                                },
                                {
                                    title: 'Digital trend',
                                    description:
                                        'Choose a region and date to display the key summary statistics and charts',
                                    page: '/digital-integration/trend',
                                    permission: 'DI_TREND',
                                },
                            ],
                        },
                        {
                            title: 'Pivot tables',
                            bullet: 'dot',
                            description: 'Drill down analysis of digital integration pivot tables',
                            icon: 'flaticon-squares-3',
                            permission: ['DI_PROFILING_A', 'DI_TREND_A'],
                            submenu: [
                                {
                                    title: 'Profiling',
                                    description: 'Drill down analysis report',
                                    page: '/analysis/digital-integration:profile',
                                    permission: 'DI_PROFILING_A',
                                },
                                {
                                    title: 'Trend',
                                    description: 'Drill down analysis report',
                                    page: '/analysis/digital-integration:trend',
                                    permission: 'DI_TREND_A',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            page: '/downloads/digital-marketing',
                            permission: ['DI_DOWNLOADS'],
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            page: '/downloads/digital-marketing/help',
                            permission: ['DI_HELP'],
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Tourism survey data',
                    root: true,

                    icon: 'flaticon-computer',
                    permission: ['TOURISM_SURVEY'],
                    submenu: [
                        {
                            title: 'Dashboards',
                            description: 'Interactive and exportable dashboards',
                            bullet: 'dot',
                            icon: 'flaticon-graphic-2',
                            permission: ['TRA_VISITORPROFILE', 'TRA_TIMESERIES'],
                            submenu: [
                                {
                                    title: 'Visitor profile',
                                    description:
                                        'Choose a region and date to display the key summary statistics and charts',
                                    page: '/survey/visitor-profile',
                                    permission: 'TRA_VISITORPROFILE',
                                },
                                {
                                    title: 'Time series',
                                    description:
                                        'Choose a region and date to display the key summary statistics and charts',
                                    page: '/survey/time-series',
                                    permission: 'TRA_TIMESERIES',
                                },
                            ],
                        },
                        {
                            title: 'Pivot tables',
                            description: 'Drill down analysis of Tourism Research Data',
                            icon: 'flaticon-squares-3',
                            bullet: 'dot',
                            permission: ['SURVEY_DO', 'SURVEY_INT', 'SURVEY_DD'],
                            submenu: [
                                {
                                    title: 'Domestic overnight',
                                    description: 'Drill down analysis of domestic overnight travel',
                                    page: '/analysis/survey:domestic-overnight',
                                    permission: 'SURVEY_DO',
                                },
                                {
                                    title: 'Domestic daytrip',
                                    description: 'Drill down analysis of domestic daytrip travel',
                                    page: '/analysis/survey:domestic-daytrip',
                                    permission: 'SURVEY_DD',
                                },
                                {
                                    title: 'International',
                                    description: 'Drill down analysis of international travel',
                                    page: '/analysis/survey:international',
                                    permission: 'SURVEY_INT',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            page: '/downloads/tourism-survey',
                            permission: ['TS_DOWNLOADS'],
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            page: '/downloads/tourism-survey/help',
                            permission: ['TS_HELP'],
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Performance indicators',
                    root: true,
                    icon: 'flaticon2-analytics',
                    permission: ['TOURISM_INDICATORS'],
                    submenu: [
                        {
                            title: 'Dashboards',
                            description:
                                'Interactive dashboards showing indicators statistics and charts',
                            icon: 'flaticon-graphic-2',
                            bullet: 'dot',
                            permission: ['METRICS_DASHBOARD'],
                            submenu: [
                                {
                                    title: 'Indicators & metrics',
                                    page: '/metrics/portal',
                                    description:
                                        'Key performance indicators updated weekly by region',
                                    permission: 'METRICS_DASHBOARD',
                                },
                            ],
                        },
                        {
                            title: 'Pivot tables',
                            description:
                                'Drill-down analysis of performance indicators statistics and data',
                            icon: 'flaticon-squares-3',
                            permission: ['METRICS_ANALYSIS'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Metrics',
                                    page: '/analysis/metrics:values',
                                    description:
                                        'Drill-down analysis of key performance indicators',
                                    permission: 'METRICS_ANALYSIS',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            page: '/downloads/performance-indicators',
                            permission: ['TI_DOWNLOADS'],
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['TI_HELP'],
                            page: '/downloads/performance-indicators/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Tourism performance',
                    root: true,
                    icon: 'flaticon-analytics',
                    permission: ['TOURISM_PERFORMANCE'],
                    submenu: [
                        {
                            title: 'Dashboards',
                            description:
                                'Interactive dashboards showing tourism performance statistics and charts',
                            icon: 'flaticon-graphic-2',
                            permission: ['LGA_TRIAL'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Snapshot',
                                    description:
                                        'Summary tourism performance statistics updated monthly by region',
                                    page: '/regions/lga',
                                    permission: 'LGA_TRIAL',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            permission: ['TP_DOWNLOADS'],
                            page: '/downloads/tourism-performance',
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['TP_HELP'],
                            page: '/downloads/tourism-performance/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Key account data',
                    root: true,
                    icon: 'flaticon-squares-3',
                    permission: ['KEY_ACCOUNT_DATA_MODULE'],
                    submenu: [
                        {
                            title: 'Pivot Tables',
                            description: 'Drill-down analysis of retail statistics and data',
                            icon: 'flaticon-graphic-2',
                            permission: [
                                'KAD_ANALYSIS_PERFORMANCE',
                                'KAD_ANALYSIS_INVENTORY',
                                'KAD_ANALYSIS_OPPORTUNITIES',
                                'KAD_ANALYSIS_VENDOR',
                            ],
                            submenu: [
                                {
                                    title: 'Performance',
                                    description: 'Performance statistics',
                                    permission: ['KAD_ANALYSIS_PERFORMANCE'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'SKU Trend',
                                            page: '/analysis/kad:performance-sku',
                                            permission: ['KAD_ANALYSIS_PERFORMANCE'],
                                        },
                                        {
                                            title: 'Quantity Trend',
                                            page: '/analysis/kad:performance-quantity',
                                            permission: ['KAD_ANALYSIS_PERFORMANCE'],
                                        },
                                        {
                                            title: 'Revenue Trend',
                                            page: '/analysis/kad:performance-revenue',
                                            permission: ['KAD_ANALYSIS_PERFORMANCE'],
                                        },
                                        {
                                            title: 'Top performing Brands',
                                            page: '/analysis/kad:top-brands',
                                            permission: ['KAD_ANALYSIS_PERFORMANCE'],
                                        },
                                    ],
                                },
                                {
                                    title: 'Inventory',
                                    description: 'Inventory statistics',
                                    permission: ['KAD_ANALYSIS_INVENTORY'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'Inventory Trend',
                                            page: '/analysis/kad:inventory-trend',
                                            permission: ['KAD_ANALYSIS_INVENTORY'],
                                        },
                                        {
                                            title: 'Inventory by Store Format',
                                            page: '/analysis/kad:inventory-store-format',
                                            permission: ['KAD_ANALYSIS_INVENTORY'],
                                        },
                                        {
                                            title: 'Inventory/Sales by Store Format',
                                            page: '/analysis/kad:inventory-sales-distribution',
                                            permission: ['KAD_ANALYSIS_INVENTORY'],
                                        },
                                        {
                                            title: 'Stock Performance by SKU',
                                            page: '/analysis/kad:stock-performance-by-sku',
                                            permission: ['KAD_ANALYSIS_INVENTORY'],
                                        },
                                        {
                                            title: 'Product Alert',
                                            page: '/analysis/kad:product-alert',
                                            permission: ['KAD_ANALYSIS_INVENTORY'],
                                        },
                                    ],
                                },
                                {
                                    title: 'Store',
                                    description: 'Store Opportunities Statistics',
                                    permission: ['KAD_ANALYSIS_OPPORTUNITIES'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'Store Format $ Contribution',
                                            page: '/analysis/kad:opportunities-store-format',
                                            permission: ['KAD_ANALYSIS_OPPORTUNITIES'],
                                        },
                                        {
                                            title: 'Brand Retail Change',
                                            page: '/analysis/kad:opportunities-brand-retail-change',
                                            permission: ['KAD_ANALYSIS_OPPORTUNITIES'],
                                        },
                                        {
                                            title: 'Brand Value Share',
                                            page: '/analysis/kad:opportunities-brand-value-share',
                                            permission: ['KAD_ANALYSIS_OPPORTUNITIES'],
                                        },
                                        {
                                            title: 'Store Opportunities',
                                            page: '/analysis/kad:store-opportunities',
                                            permission: ['KAD_ANALYSIS_OPPORTUNITIES'],
                                        },
                                    ],
                                },
                                {
                                    title: 'Vendor',
                                    description: 'Vendor Statistics',
                                    permission: ['KAD_ANALYSIS_VENDOR'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'Performance Overview',
                                            page: '/analysis/kad:performance-overview',
                                            permission: ['KAD_ANALYSIS_VENDOR'],
                                        },
                                        {
                                            title: 'Category Leaderboard',
                                            page: '/analysis/kad:category-leaderboard',
                                            permission: ['KAD_ANALYSIS_VENDOR'],
                                        },
                                        {
                                            title: 'Store Format Leaderboard',
                                            page: '/analysis/kad:store-format-leaderboard',
                                            permission: ['KAD_ANALYSIS_VENDOR'],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Downloads',
                            description: 'Data downloads',
                            icon: 'flaticon-download-1',
                            permission: ['KAD_ANALYSIS_STANDARD_DNLD', 'KAD_ANALYSIS_BASIC_DNLD'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Basic Download',
                                    description: 'Downloadable CSV file',
                                    permission: ['KAD_ANALYSIS_BASIC_DNLD'],
                                    page: 'data-download/kad:basic',
                                },
                                {
                                    title: 'Standard Download',
                                    description: 'Downloadable CSV file',
                                    page: 'data-download/kad:standard',
                                    permission: ['KAD_ANALYSIS_STANDARD_DNLD'],
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            permission: ['KAD_DOWNLOADS'],
                            page: '/downloads/key-account-data',
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['KAD_HELP'],
                            page: '/downloads/key-account-data/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Syndicated data',
                    root: true,
                    icon: 'flaticon-squares-3',
                    permission: ['ASGA_MODULE'],
                    submenu: [
                        {
                            title: 'Pivot Tables',
                            description: 'Drill-down analysis of retail statistics and data',
                            icon: 'flaticon-graphic-2',
                            permission: ['ASGA_CUBES'],
                            submenu: [
                                {
                                    title: 'Brands',
                                    description: 'Performance statistics',
                                    permission: ['ASGA_CUBES'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'Brand share',
                                            page: '/analysis/asga:brand-share',
                                            permission: ['ASGA_CUBES'],
                                        },
                                        {
                                            title: 'Brand category share',
                                            page: '/analysis/asga:brand-category-share',
                                            permission: ['ASGA_CUBES'],
                                        },
                                    ],
                                },
                                {
                                    title: 'Price',
                                    description: 'Inventory statistics',
                                    permission: ['ASGA_CUBES'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'Brand price trend',
                                            page: '/analysis/asga:brand-price-trend',
                                            permission: ['ASGA_CUBES'],
                                        },
                                        {
                                            title: 'Category price trend',
                                            page: '/analysis/asga:category-price-trend',
                                            permission: ['ASGA_CUBES'],
                                        },
                                        {
                                            title: 'Price brand share',
                                            page: '/analysis/asga:price-brand-share',
                                            permission: ['ASGA_CUBES'],
                                        },
                                        {
                                            title: 'Price category share',
                                            page: '/analysis/asga:price-category-share',
                                            permission: ['ASGA_CUBES'],
                                        },
                                    ],
                                },
                                {
                                    title: 'Features',
                                    description: 'Store Opportunities Statistics',
                                    permission: ['ASGA_CUBES'],
                                    bullet: 'dot',
                                    submenu: [
                                        {
                                            title: 'Category metrics',
                                            page: '/analysis/asga:category-metrics',
                                            permission: ['ASGA_CUBES'],
                                        },
                                        {
                                            title: 'Subcategory metrics',
                                            page: '/analysis/asga:subcategory-metrics',
                                            permission: ['ASGA_CUBES'],
                                        },
                                        {
                                            title: 'Gender share',
                                            page: '/analysis/asga:gender-share',
                                            permission: ['ASGA_CUBES'],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            permission: ['SD_DOWNLOADS'],
                            page: '/downloads/asga',
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['SD_HELP'],
                            page: '/downloads/asga/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Manage locations',
                    root: true,
                    icon: 'flaticon-settings',
                    permission: ['ASSET_MANAGER'],
                    submenu: [
                        {
                            title: 'Assets manager',
                            description: 'Manage assets in your organisation',
                            icon: 'flaticon-placeholder-3',
                            permission: ['TR_SITES', 'TR_UPLOAD', 'ASSETS_UPLOAD'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Asset admin',
                                    page: '/traffic/assets',
                                    permission: 'TR_SITES',
                                },
                                {
                                    title: 'Upload Assets (Billboards)',
                                    page: '/traffic/assets/site-upload',
                                    permission: 'TR_UPLOAD',
                                },
                                {
                                    title: 'Upload assets',
                                    page: '/traffic/assets/upload',
                                    permission: 'ASSETS_UPLOAD',
                                },
                            ],
                        },
                        {
                            title: 'Pack manager',
                            description: 'Manage collections of assets in your organisation',
                            icon: 'flaticon-map-location',
                            permission: ['TR_PACKS'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Pack admin',
                                    page: '/traffic/assets/packs',
                                    permission: 'TR_PACKS',
                                },
                            ],
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['AM_HELP'],
                            page: '/downloads/asset-manager/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Asset profiler',
                    root: true,
                    icon: 'flaticon2-graphic-design',
                    permission: ['STORE_PROFILER'],
                    submenu: [
                        {
                            title: 'Dashboards',
                            description:
                                'Interactive dashboards showing store statistics and charts',
                            icon: 'flaticon-graphic-2',
                            bullet: 'dot',
                            permission: ['SP_GEOVIZ', 'ASSETS_VISITATION'],
                            submenu: [
                                {
                                    title: 'Asset Profiler Geoviz',
                                    page: 'traffic/asset/geoviz',
                                    description: 'Asset Profiler Geoviz',
                                    permission: 'SP_GEOVIZ',
                                },
                                {
                                    title: 'Asset Profiler Visitation',
                                    page: 'traffic/asset/visitation',
                                    description: 'Asset Profiler Visitation',
                                    permission: 'ASSETS_VISITATION',
                                },
                            ],
                        },
                        {
                            title: 'Pivot tables',
                            description:
                                'Drill-down analysis of asset dashboard statistics and data',
                            icon: 'flaticon-squares-3',
                            permission: ['SP_ANALYSIS'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Asset profiler',
                                    page: '/traffic/asset/analysis',
                                    description: 'Drill-down analysis of key store performance',
                                    permission: 'SP_ANALYSIS',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            permission: ['SP_DOWNLOADS'],
                            page: '/downloads/asset-profiler',
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['SP_HELP'],
                            page: '/downloads/asset-profiler/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Audience reach',
                    root: true,
                    icon: 'flaticon-placeholder-3',
                    permission: ['AUDIENCE_REACH'],
                    submenu: [
                        {
                            title: 'Audience reach status',
                            description: 'Analysis data retrievals and their status',
                            icon: 'flaticon-calendar-with-a-clock-time-tools',
                            page: '/traffic/sites/analysis/status',
                            permission: 'AUDIENCE_REACH',
                            single: true,
                        },
                        {
                            title: 'Audience reach geo-pivots',
                            description: 'Site based drill down analysis cubes',
                            icon: 'flaticon-map-location',
                            permission: ['AUDIENCE_REACH'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Hour',
                                    page: '/traffic/sites/analysis/hour',
                                    permission: 'AR_HOUR',
                                },
                                {
                                    title: 'Daypart',
                                    page: '/traffic/sites/analysis/daypart',
                                    permission: 'AR_DAYPART',
                                },
                                {
                                    title: 'Daily',
                                    page: '/traffic/sites/analysis/day',
                                    permission: 'AR_DAILY',
                                },

                                {
                                    title: 'Weekday',
                                    page: '/traffic/sites/analysis/weekday',
                                    permission: 'AR_WEEKDAY',
                                },

                                {
                                    title: 'Week',
                                    page: '/traffic/sites/analysis/week',
                                    permission: 'AR_WEEK',
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'Audience profiler',
                    root: true,
                    icon: 'flaticon2-group',
                    permission: ['AUDIENCE_PROFILER'],
                    submenu: [
                        {
                            title: 'Audience profiler geo-pivots',
                            description: 'asset based drill down analysis cubes',
                            icon: 'flaticon-map-location',
                            permission: ['AUDIENCE_PROFILER'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Category spend',
                                    page: '/traffic/audience/analysis/site-region-audience-spend',
                                    permission: 'AP_CATEGORY_SPEND',
                                },
                                {
                                    title: 'Category spend index',
                                    page: '/traffic/audience/analysis/site-spend-profile',
                                    permission: 'AP_CATEGORY_SPEND_INDEX',
                                },
                                {
                                    title: 'Category spend index - global',
                                    page: '/traffic/audience/analysis/site-spend-global-profile',
                                    permission: 'AP_CATEGORY_SPEND_GLOBAL_INDEX',
                                },
                                {
                                    title: 'ABS profile index - household',
                                    page: '/traffic/audience/analysis/site-abs-household-summary',
                                    permission: 'AP_ABS_PROFILE_INDEX',
                                },
                                {
                                    title: 'ABS profile - household',
                                    page: '/traffic/audience/analysis/site-region-abs-household-detail',
                                    permission: 'AP_ABS_PROFILE',
                                },
                                {
                                    title: 'ABS profile index - individual',
                                    page: '/traffic/audience/analysis/site-abs-individual-summary',
                                    permission: 'AP_ABS_PROFILE_INDEX_INDIVIDUAL',
                                },
                                {
                                    title: 'ABS profile - individual',
                                    page: '/traffic/audience/analysis/site-region-abs-individual-detail',
                                    permission: 'AP_ABS_PROFILE_INDIVIDUAL',
                                },
                                {
                                    title: 'Brand spend',
                                    page: '/traffic/audience/analysis/site-region-audience-brand-spend',
                                    permission: 'AP_BRAND_SPEND',
                                },
                                {
                                    title: 'Brand spend index',
                                    page: '/traffic/audience/analysis/site-brand-spend-profile',
                                    permission: 'AP_BRAND_SPEND_INDEX',
                                },
                            ],
                        },
                        {
                            title: 'Reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon-interface-11',
                            permission: ['AP_DOWNLOADS'],
                            page: '/downloads/audience-profiler',
                            single: true,
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',

                            page: '/downloads/audience-profiler/help',
                            permission: ['AP_HELP'],
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Region analysis',
                    root: true,
                    icon: 'flaticon-map-location',
                    permission: ['REGION_ANALYSIS'],
                    submenu: [
                        {
                            title: 'Region geo-pivots',
                            description: 'Region based drill down analysis cubes',
                            icon: 'flaticon-map-location',
                            permission: ['RA_REGION_CATEGORY_SPEND'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Region category spend',
                                    page: '/traffic/region/analysis/region-spend',
                                    permission: 'RA_REGION_CATEGORY_SPEND',
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'Experimental',
                    root: false,

                    icon: 'flaticon2-rhombus',
                    permission: 'DEMO',
                    submenu: [
                        {
                            title: 'Dashboards',
                            description: 'These pages are for investigation or demo purposes only',
                            icon: 'flaticon-graphic-2',
                            permission: ['DEMO'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Path explorer',
                                    description:
                                        'Load Hexagon data from a data source to visualise paths travelled',
                                    page: '/demo/path',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Demographics (Limited Data)',
                                    page: '/regions/demographics',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Sentiment (Limited Data)',
                                    page: '/regions/sentiment',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Sydney Bus Network',
                                    page: '/demo/demo2',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Trade',
                                    page: '/demo/trade',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'H3 Sample',
                                    page: '/regions/h3-sample',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Isochrone Playground',
                                    page: '/demo/isochrones',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Choropleth Playground',
                                    page: '/demo/choropleths',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Event Playground',
                                    page: '/demo/event-playground',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Explo Dashboard',
                                    page: '/demo/explo',
                                    permission: 'DEMO',
                                },
                            ],
                        },
                        {
                            title: 'Pivot tables',
                            description: 'These pages are for investigation or demo purposes only',
                            icon: 'flaticon-squares-3',
                            permission: 'DEMO',
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Profiler - Device GPS',
                                    page: '/analysis/region:device-gps',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Transactions - Visa Domestic',
                                    page: '/analysis/region:ifi-domestic',
                                    permission: 'DEMO',
                                },
                                {
                                    title: 'Transactions - Visa International',
                                    page: '/analysis/region:ifi-international',
                                    permission: 'DEMO',
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'Campaign analysis',
                    root: true,
                    icon: 'flaticon2-digital-marketing',
                    permission: ['POST_ANALYSIS'],
                    submenu: [
                        {
                            title: 'Campaign analysis status',
                            description: 'View campaigns and their reports',
                            icon: 'flaticon-calendar-with-a-clock-time-tools',
                            permission: ['PA_VIEWER'],
                            page: '/traffic/campaign/status',
                            bullet: 'dot',
                            single: true,
                        },
                        {
                            title: 'Campaign geo-pivots',
                            description: 'Campaign based drill down analysis cubes',
                            icon: 'flaticon-map-location',
                            permission: ['PA_ANALYSIS'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Pre-campaign analysis',
                                    description: 'Drill down analysis of pre campaigns',
                                    page: '/traffic/campaign/pre-analysis',
                                    permission: 'PA_PRE_ANALYSIS',
                                },
                                {
                                    title: 'Campaign analysis',
                                    description: 'Drill down analysis of completed campaigns',
                                    page: '/traffic/campaign/analysis',
                                    permission: 'PA_ANALYSIS',
                                },
                            ],
                        },
                        {
                            title: 'Campaign reports',
                            description:
                                'Downloadable slides and infographics for redistribution / publishing',
                            icon: 'flaticon2-document',
                            permission: ['PA_DOWNLOADS'],
                            bullet: 'dot',
                            submenu: [
                                {
                                    title: 'Post analysis',
                                    description: 'Drill down analysis of pre campaigns',
                                    page: '/downloads/campaign-analysis',
                                    permission: ['PA_DOWNLOADS'],
                                },
                            ],
                        },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['PA_HELP'],
                            page: '/downloads/campaign-analysis/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Data centre',
                    root: true,
                    icon: 'flaticon-multimedia-3',
                    permission: ['DATACENTRE'],
                    submenu: [
                        {
                            title: 'Upload data',
                            description: 'Submit your data for processing',
                            icon: 'flaticon-upload-1',
                            page: '/data-centre/submissions',
                            permission: ['DATACENTRE_UPLOAD'],
                            single: true,
                        },

                        // {
                        //     title: 'Admin & Upload',
                        //     description: 'Upload and configure data and reports',
                        //     icon: 'flaticon-upload-1',
                        //     bullet: 'dot',
                        //     permission: [
                        //         'DATACENTRE_UPLOAD',
                        //         'DATACENTRE_DASHBOARD',
                        //         'DATACENTRE_LIST',
                        //         'SYS_ADMIN',
                        //     ],
                        //     submenu: [
                        //         {
                        //             title: 'Upload data',
                        //             description: 'List your data',
                        //             page: '/data-centre/submissions',
                        //             permission: ['DATACENTRE_UPLOAD'],
                        //         },
                        //         {
                        //             title: 'Upload reports',
                        //             description: 'Upload reports',
                        //             page: '/data-centre/upload-reports',
                        //             permission: 'SYS_ADMIN',
                        //         },
                        //         {
                        //             title: 'Configure reports',
                        //             description: 'Configure reports',
                        //             page: '/data-centre/configure-reports',
                        //             permission: 'SYS_ADMIN',
                        //         },
                        //         {
                        //             title: 'Configure indicators',
                        //             description: 'Configure indicators',
                        //             page: '/data-centre/configure-indicators',
                        //             permission: 'SYS_ADMIN',
                        //         },
                        //     ],
                        // },
                        {
                            title: 'Help & Documentation',
                            description: 'Downloadable help and documentation',
                            icon: 'flaticon2-help',
                            permission: ['DC_HELP'],
                            page: '/downloads/data-centre/help',
                            single: true,
                        },
                    ],
                },
                {
                    title: 'Admin',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon-settings',
                    permission: ['SYS_ADMIN', 'CLIENT_ADMIN'],
                    submenu: [
                        {
                            title: 'Manage organisations',
                            description: 'Manage existing organisations and settings',
                            page: '/admin/organisations',
                            permission: ['SYS_ADMIN', 'CLIENT_ADMIN'],
                        },
                        {
                            title: 'Configure reports',
                            description: 'Configure reports',
                            page: '/data-centre/configure-reports',
                            permission: 'SYS_ADMIN',
                        },
                        {
                            title: 'Upload reports',
                            description: 'Upload reports',
                            page: '/data-centre/upload-reports',
                            permission: ['SYS_ADMIN', 'CLIENT_ADMIN'],
                        },
                        {
                            title: 'Configure indicators',
                            description: 'Configure indicators',
                            page: '/data-centre/configure-indicators',
                            permission: 'SYS_ADMIN',
                        },
                        {
                            title: 'Indicator Manager',
                            description: 'Manage your indicators',
                            page: '/data-centre/indicator-manager',
                            permission: 'SYS_ADMIN',
                        },
                        {
                            title: 'Cube Explorer',
                            description: 'Explore data cubes',
                            page: '/analysis/explorer',
                            permission: 'SYS_ADMIN',
                        },
                    ],
                },
            ],
        },
        admin: {
            permission: ['SYS_ADMIN', 'CLIENT_ADMIN'],
            items: [
                {
                    title: 'Manage Organisations',
                    description: 'Manage existing organisations and settings',
                    page: '/admin/organisations',
                    permission: ['SYS_ADMIN', 'CLIENT_ADMIN'],
                },
                {
                    title: 'Configure reports',
                    description: 'Configure reports',
                    page: '/data-centre/configure-reports',
                    permission: 'SYS_ADMIN',
                },
                {
                    title: 'Configure indicators',
                    description: 'Configure indicators',
                    page: '/data-centre/configure-indicators',
                    permission: 'SYS_ADMIN',
                },
            ],
        },
    };

    helpMenu = {
        title: 'Help',
        root: true,
        permission: [],
        icon: 'flaticon-questions-circular-button',
        'icon-only': true,

        //   'custom-class': 'kt-menu__item--bottom-1',
        submenu: [
            {
                title: 'Customer Support',
                description: 'Get help from our customer support portal',
                bullet: 'none',
                permission: [],
                icon: 'flaticon-support',
                link: environment.externalUris.support,
                target: '_blank',
            },
        ],
    };

    constructor() {
        // side and header are the same except aside has the help menu
        this.defaults.aside = { items: [...this.defaults.header.items, this.helpMenu] };
    }

    public get configs(): AppMenus {
        return this.defaults;
    }
}
