import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'hm-submission-upload-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
    selectedYear: number = 2020;
    selectedPeriod: string = 'Q2';
    selectedDate: string = `${this.selectedYear}-${this.selectedPeriod}`;

    filterData: any;

    enableQuarters: boolean;
    enableMonths: boolean;

    // **Optional**
    // YYYY-MMM or YYYY-QQ
    minDate: Date;
    maxDate: Date;

    minYear: number;
    maxYear: number;

    // MMM or QQ
    minPeriod: string;
    maxPeriod: string;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.filterData = this.data;

        if (this.data.minDate) {
            this.setMinDate(this.data.minDate);
        }
        if (this.data.maxDate) {
            this.setMaxDate(this.data.maxDate);
        }

        this.setSelectedDate(this.data.date);

        if (this.data.enableMonths) {
            this.enableMonths = true;
        }

        if (this.data.enableQuarters) {
            this.enableQuarters = true;
        }

        const rightMostPos = window.innerWidth - Number(this.filterData.left);
        this.dialogRef.updatePosition({
            top: `${this.filterData.top}px`,
            right: `${rightMostPos}px`,
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    decrementYear() {
        if (this.minYear) {
            if (this.selectedYear > this.minYear) {
                --this.selectedYear;
            }
            this.checkValidSelectedPeriod();
        } else {
            --this.selectedYear;
        }
    }

    incrementYear() {
        if (this.maxYear) {
            if (this.selectedYear < this.maxYear) {
                ++this.selectedYear;
            }
            this.checkValidSelectedPeriod();
        } else {
            ++this.selectedYear;
        }
    }

    calcDate(period: string) {
        this.selectedPeriod = period;
        this.selectedDate = this.selectedYear.toString() + '-' + period;
        this.dialogRef.close(this.selectedDate);
    }

    setSelectedDate(date: string) {
        const dateArray = date.split('-');

        this.selectedDate = date;
        this.selectedYear = parseInt(dateArray[0]);
        this.selectedPeriod = dateArray[1];
    }

    /**
     * Input format could be yyyy-mmm or yyyy-qq
     * the format if enable months is true, other qq
     */
    setMinDate(date: string) {
        const minDate = date.split('-');

        this.minYear = parseInt(minDate[0]);
        this.minPeriod = minDate[1];

        this.minDate = new Date(`${this.minYear}-${this.minPeriod}`);
    }

    setMaxDate(date: string) {
        const maxDate = date.split('-');

        this.maxYear = parseInt(maxDate[0]);
        this.maxPeriod = maxDate[1];

        this.maxDate = new Date(`${this.maxYear}-${this.maxPeriod}`);
    }

    // checkValidity() checks if months are enabled in the date picker. If they are,
    //  check if all dates and selected dates are within limits of min and max date
    checkValidMonth(month: string): boolean {
        if (this.enableMonths) {
            const dateString = `${this.selectedYear}-${month}`;
            const date = new Date(dateString);

            if (date < this.minDate || date > this.maxDate) {
                // this.selectedPeriod = this.minPeriod;
                return false;
            }

            return true;
        }
        return false;
    }

    // No limits are imposed on the quarters, for now it just checks if quarters are enabled
    checkValidQuarter(): boolean {
        return this.enableQuarters;
    }

    checkValidSelectedPeriod() {
        const selectedDateString = `${this.selectedYear}-${this.selectedPeriod}`;
        const selectedDate = new Date(selectedDateString);

        // selectedDate should only be mutated if the selectedPeriod changed
        if (selectedDate < this.minDate) {
            this.selectedPeriod = this.minPeriod;
            this.selectedDate = `${this.selectedYear}-${this.selectedPeriod}`;
        } else if (selectedDate > this.maxDate) {
            this.selectedPeriod = this.maxPeriod;
            this.selectedDate = `${this.selectedYear}-${this.selectedPeriod}`;
        }
    }
}
